import * as _ from "lodash";
import { SoccerGameEvent } from "@ollie-sports/models";
import md5 from "md5";
import stableStringify from "json-stable-stringify";
import { getUniversalHelpers } from "../helpers";
import { BatchTask } from "@ollie-sports/firebase";
import { common__client__getGenerator, common__compressString, common__decompressString } from "./common.api";

export async function soccerGameEventBundle__client__getEvents(p: {
  soccerGameId: string;
}): Promise<SoccerGameEvent[] | undefined> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const bundles = await h.SoccerGameEventBundle.query({ where: [{ soccerGameId: ["==", p.soccerGameId] }] });

  const bundle = bundles.docs[0];
  if (!bundle) {
    return undefined;
  } else {
    return JSON.parse(common__decompressString({ str: bundle.compressedJSON }));
  }
}

//There's some weird logic in this function that I'd like to keep colocated. So even though it's an API endpoint, return a batch task
export async function soccerGameEventBundle__client__refresh(p: {
  finalEvents: SoccerGameEvent[];
  soccerGameId: string;
  calendarEntryId: string;
  teamId: string;
  returnBatchTask: boolean;
}): Promise<BatchTask> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const compressedJSON = common__compressString({ str: JSON.stringify(p.finalEvents) });
  const docId = common__client__getGenerator().SoccerGameEventBundleId(p.soccerGameId);
  return await h.SoccerGameEventBundle.set(
    {
      id: docId,
      doc: {
        id: docId,
        createdAtMS: Date.now(),
        calendarEntryId: p.calendarEntryId,
        teamId: p.teamId,
        compressedJSON,
        alg: "pako",
        md5OfEvents: md5(stableStringify(p.finalEvents)),
        soccerGameId: p.soccerGameId
      }
    },
    { returnBatchTask: p.returnBatchTask }
  );
}

// i18n certified - complete
